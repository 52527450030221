import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";

const PostVideoModal = ({ show, onHide, onOptionSelected }) => {
  return (
    <Modal
      show={show}
      //   onHide={onHide}
      centered
      size="lg"
      className="post-video-modal"
    >
      <Modal.Header closeButton>
        {/* <Modal.Title>
          <div className="font-smaller">What would you like to do next?</div>
        </Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <Row className="text-center">
          <Col md={6} className="mb-3">
            <div className="option-card p-4 h-100 d-flex flex-column justify-content-between">
              <div>
                <h4>Questions?</h4>
                <p>
                  Learn more about JobInfoNetwork and how it can help your
                  organization.
                </p>
              </div>
              <a
                href="https://jobinfonetwork.com/faq"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary mt-3 text-center justify-content-center w-100"
              >
                Visit FAQ
              </a>
            </div>
          </Col>
          <Col md={6} className="mb-3">
            <div className="option-card p-4 h-100 d-flex flex-column justify-content-between">
              <div>
                <h4>Get Started</h4>
                <p>
                  Register your group to put JobInfoNetwork to work for your
                  organization.
                </p>
              </div>
              <a
                href="https://app.jobinfonetwork.com/#/register"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-success mt-3 text-center justify-content-center w-100"
              >
                Register Now
              </a>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide} className="text-center">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PostVideoModal;
