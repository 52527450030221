import React, { useEffect, useState } from "react";
import { Col, Row, Container, Button, ProgressBar } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import StartupHeader from "../../../Components/StartupHeader";
import VideoPlayer from "./VideoPlayer";
import PostVideoModal from "./PostVideoModal";

const introVideoParts = [
  {
    categoryTitle: "Introduction",
    categoryDescription: "",
    videoUrl:
      "https://elqmq.upcloudobjects.com/demo-videos/mod/v1-sample-group-intro.mp4",
    videoStyle: "landscape",
    timeStampDetails: [
      {
        title: "Introduction to JobInfoNetwork",
        startTime: 0,
        endTime: 10,
        description: "",
      },
      {
        title: "Two Important Points",
        startTime: 10,
        endTime: 58,
        description:
          "**a.** Employed people are motivated to assist job seekers within their Church, Charitable Organization, etc.\n\n**b.** Employed people should be able to decide if/when job seeker receives their contact info.",
      },
      {
        title: "Enormous, Untapped Potential",
        startTime: 58,
        endTime: 60 + 18,
        description: "",
      },
      {
        title: "JobInfoNetwork Leverages This Potential",
        startTime: 60 + 18,
        endTime: 60 + 38,
        description: "",
      },
      {
        title: "Emails Received Re:JobInfoNetwork",
        startTime: 60 + 38,
        endTime: 60 + 52,
        description: "",
      },
    ],
  },
  // Placeholder for demo video
  {
    categoryTitle: "Job Seeker Sends A Networking Request",
    timeStampDetails: [{ startTime: 0, endTime: 0 }],
  },
  {
    categoryTitle: "Employed {{MEMBER_NOUN}} Receives Networking Request",
    timeStampDetails: [{ startTime: 0, endTime: 0 }],
  },
  {
    categoryTitle: "Job Seeker Receives Response",
    timeStampDetails: [{ startTime: 0, endTime: 0 }],
  },
];
const demoVideoParts = [
  {
    categoryTitle: "Introduction",
    timeStampDetails: [{ startTime: 0, endTime: 0 }],
  },
  {
    categoryTitle: "Job Seeker Sends A Networking Request",
    categoryDescription: "",
    videoUrl:
      "https://elqmq.upcloudobjects.com/demo-videos/mod/v2-sample-group.mp4",
    videoStyle: "portrait",
    timeStampDetails: [
      {
        title: "Login as a Job Seeker",
        startTime: -1,
        endTime: 32,
        description: "",
        slideText: `**Employed {{MEMBER_NOUN}}s:**\n Employed Individuals who are {{member_noun}}s of your Organization\n\n**{{JOBSEEKER_NAME}}:**\nSample {{INDUSTRY}} Job Seeker`,
      },
      {
        title: "Navigate to the Networking Page",
        startTime: 32,
        endTime: 49,
        description: "",
        slideText: `{{JOBSEEKER_NAME_FIRST}} Networks`,
      },
      {
        title: "Filter by Industry and Group",
        startTime: 49,
        endTime: 60 + 20,
        description: "",
        slideText: `{{JOBSEEKER_NAME_FIRST}} Filters by Industry and Group`,
      },
      {
        title: "Select an employed {{MEMBER_NOUN}} to network with",
        startTime: 60 + 20,
        endTime: 60 * 2 + 19,
        description: "",
        slideText:
          "{{JOBSEEKER_NAME_FIRST}} identifies ideal network candidates",
      },
      {
        title: "Send a Saved Resume with the Networking Request",
        startTime: 60 * 2 + 19,
        endTime: 60 * 2 + 38,
        description: "",
        slideText:
          "Job Seeker {{JOBSEEKER_NAME_FIRST}} sends resume & request to network to chosen Employed {{MEMBER_NOUN}}s",
      },
    ],
  },
  {
    categoryTitle: "Employed {{MEMBER_NOUN}} Receives Networking Request",
    categoryDescription: "",
    videoUrl:
      "https://elqmq.upcloudobjects.com/demo-videos/mod/v2-sample-group.mp4",
    videoStyle: "portrait",
    timeStampDetails: [
      {
        title: "Employed {{MEMBER_NOUN}} logs in to account",
        startTime: 60 * 2 + 38,
        endTime: 60 * 3,
        description: "",
        slideText:
          "Employed {{MEMBER_NOUN}} & {{STM_POSITION}}, **{{STM_NAME}}**\n logs in",
      },
      {
        title: "Networking Request Notification",
        startTime: 60 * 3,
        endTime: 60 * 3 + 19,
        description: "",
        slideText:
          "Employed {{MEMBER_NOUN}} {{STM_NAME_FIRST}} received notice of networking request in Messages and in their email",
      },
      {
        title: "View Networking Request in Messages",
        startTime: 60 * 3 + 19,
        endTime: 60 * 3 + 30,
        description: "",
        slideText:
          "Employed {{MEMBER_NOUN}} {{STM_NAME_FIRST}} received notice of networking request in Messages and in their email",
      },
      {
        title: "Navigate to the Connections Page",
        startTime: 60 * 3 + 30,
        endTime: 60 * 3 + 38,
        description: "",
        slideText: "{{STM_NAME_FIRST}} goes to **Connections**",
      },
      {
        title: "Reviews Job Seeker's resume",
        startTime: 60 * 3 + 38,
        endTime: 60 * 4 + 8,
        description: "",
        slideText: `Employed {{MEMBER_NOUN}} {{STM_NAME_FIRST}} reviews {{JOBSEEKER_NAME_FIRST}}'s resume, thinking of ways to help`,
      },
      {
        title: "Review 3 response options",
        startTime: 60 * 4 + 8,
        endTime: 60 * 5 + 2,
        description: "",
        slideText:
          "Employed {{MEMBER_NOUN}} selects 1 of 3 potential responses",
      },
      {
        title: "Respond to the Job Seeker",
        startTime: 60 * 5 + 2,
        endTime: 60 * 5 + 26,
        description: "",
        slideText:
          "Employed {{MEMBER_NOUN}} {{STM_NAME_FIRST}} chooses the 1st response and provides contact information",
      },
    ],
  },
  {
    categoryTitle: "Job Seeker Receives Response",
    categoryDescription: "",
    videoUrl:
      "https://elqmq.upcloudobjects.com/demo-videos/mod/v2-sample-group.mp4",
    videoStyle: "portrait",
    timeStampDetails: [
      {
        title: "Job Seeker logs in to account ",
        startTime: 60 * 5 + 26,
        endTime: 60 * 5 + 46,
        description: "",
        slideText:
          "Job Seeker received notice in their account **and** in email that the {{STM_POSITION}} at {{STM_COMPANY}} requested contact.",
      },
      {
        title: "Navigate to the Connections Page",
        startTime: 60 * 5 + 46,
        endTime: 60 * 6 + 2,
        description: "",
        slideText: `Job Seeker {{JOBSEEKER_NAME_FIRST}} goes to **Connections**`,
      },
      {
        title: "View the Employed {{MEMBER_NOUN}}'s response",
        startTime: 60 * 6 + 2,
        endTime: 60 * 6 + 41,
        description: "",
        slideText:
          "{{JOBSEEKER_NAME_FIRST}} clicks on the Response and the full message is displayed",
      },
      {
        title: "Summary of Networking Example",
        startTime: 60 * 6 + 41,
        endTime: 60 * 7 + 30,
        description: "",
        slideText:
          "There is Real Untapped Potential:\n\n**a.** Employed {{MEMBER_NOUN}}s are motivated to assist job seekers within their Church, Charitable Organization, etc.. \n\n**b.** Your job seekers can network in their industry with those who are motivated to help.",
      },
    ],
  },
];

export default function DynamicVideoPlayer() {
  const { videoId } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeSection, setActiveSection] = useState(0);
  const [timerPaused, setTimerPaused] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(10);
  const [videoMetadata, setVideoMetadata] = useState(null);
  const [processedIntroParts, setProcessedIntroParts] =
    useState(introVideoParts);
  const [processedDemoParts, setProcessedDemoParts] = useState(demoVideoParts);
  const [showPostVideoModal, setShowPostVideoModal] = useState(false);

  const emailImageUrl =
    "https://elqmq.upcloudobjects.com/demo-videos/mod/emails.png";

  // Function to get first name from full name
  const getFirstName = (fullName) => {
    if (!fullName) return "";
    return fullName.split(" ")[0];
  };

  // Function to replace placeholders in text
  const replacePlaceholders = (text, memberNoun) => {
    if (!text || !memberNoun) return text;

    const titleCaseMemberNoun =
      memberNoun.charAt(0).toUpperCase() + memberNoun.slice(1);

    return text
      .replace(/\{\{member_noun\}\}/g, memberNoun)
      .replace(/\{\{MEMBER_NOUN\}\}/g, titleCaseMemberNoun);
  };

  // Function to process slideText with metadata
  const processSlideText = (slideText, metadata) => {
    if (!slideText || !metadata) return slideText;

    // Get first names
    const stm_name_first = getFirstName(metadata.stm_name);
    const jobseeker_name_first = getFirstName(metadata.jobseeker_name);

    // Replace all possible metadata placeholders
    let processedText = slideText;

    // Replace member_noun placeholders first
    if (metadata.member_noun) {
      processedText = replacePlaceholders(processedText, metadata.member_noun);
    }

    // Replace additional metadata placeholders
    const replacements = {
      "{{STM_NAME}}": metadata.stm_name || "",
      "{{STM_NAME_FIRST}}": stm_name_first,
      "{{STM_POSITION}}": metadata.stm_position_name || "",
      "{{STM_COMPANY}}": metadata.stm_company_name || "",
      "{{JOBSEEKER_NAME}}": metadata.jobseeker_name || "",
      "{{JOBSEEKER_NAME_FIRST}}": jobseeker_name_first,
      "{{RESUME_TITLE}}": metadata.resume_title || "",
      "{{INDUSTRY}}": metadata.industry || "",
      "{{GROUP_NAME}}": metadata.group_name || "",
      "{{JOBSEEKER_PRONOUN_POSSESSIVE}}":
        metadata.jobseeker_pronoun_possessive || "",
      "{{JOBSEEKER_PRONOUN}}": metadata.jobseeker_pronoun || "",
    };

    Object.entries(replacements).forEach(([placeholder, value]) => {
      processedText = processedText.replace(
        new RegExp(placeholder, "g"),
        value
      );
    });

    return processedText;
  };

  // Function to process video parts and replace placeholders
  const processVideoCategories = (categories, metadata) => {
    if (!metadata) return categories;

    const memberNoun = metadata.member_noun
      ? metadata.member_noun.toLowerCase()
      : "";

    return categories.map((category) => {
      // Process category title
      const categoryTitle = replacePlaceholders(
        category.categoryTitle,
        memberNoun
      );

      // Process timestamps and their content
      const timeStampDetails = category.timeStampDetails.map((detail) => {
        return {
          ...detail,
          title: replacePlaceholders(detail.title, memberNoun),
          description: replacePlaceholders(detail.description, memberNoun),
          slideText: processSlideText(detail.slideText, metadata),
        };
      });

      return {
        ...category,
        categoryTitle,
        timeStampDetails,
      };
    });
  };

  // Process video parts when metadata changes
  useEffect(() => {
    if (videoMetadata) {
      setProcessedIntroParts(
        processVideoCategories(introVideoParts, videoMetadata)
      );
      setProcessedDemoParts(
        processVideoCategories(demoVideoParts, videoMetadata)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoMetadata]);

  useEffect(() => {
    document.body.classList.add("home-page");

    // Preload the image
    const img = new Image();
    img.src = emailImageUrl;

    return function cleanup() {
      document.body.classList.remove("home-page");
    };
  }, []);

  // Fetch the video metadata when the component mounts or the videoId changes
  useEffect(() => {
    if (!videoId) {
      setError("No video ID provided");
      setLoading(false);
      return;
    }

    const fetchVideoData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `https://elqmq.upcloudobjects.com/demo-videos/mod/${videoId}_metadata.json`
        );

        if (!response.ok) {
          // If the response is a 404 (not found), redirect to videodemosample
          if (response.status === 404) {
            console.error(
              "Metadata file not found, redirecting to sample video"
            );
            history.push(`${process.env.PUBLIC_URL}/videodemosample`);
            return;
          }

          throw new Error(
            `Failed to load video data (Status: ${response.status})`
          );
        }

        const data = await response.json();
        console.log("Video metadata:", data);

        // Store the metadata in state
        setVideoMetadata(data);
        setLoading(false);
      } catch (err) {
        console.error("Error loading video metadata:", err);

        // If there's any error in fetching or parsing, redirect to sample video
        console.error("Error with metadata, redirecting to sample video");
        history.push(`${process.env.PUBLIC_URL}/videodemosample`);
      }
    };

    fetchVideoData();
  }, [videoId, history]);

  useEffect(() => {
    let interval;
    if (activeSection === 1 && !timerPaused && timeRemaining > 0) {
      interval = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeRemaining === 0 && activeSection === 1) {
      setTimeRemaining(10);
      setActiveSection(2); // Move to the next section when time runs out
    }

    return () => clearInterval(interval);
  }, [activeSection, timerPaused, timeRemaining]);

  const togglePause = () => {
    if (timerPaused) {
      setTimeRemaining(10); // Reset timer when proceeding to next section
      setActiveSection(2);
      setTimerPaused(false);
    } else {
      setTimerPaused(!timerPaused); // Toggle pause state
    }
  };

  const handleOptionSelected = (option) => {
    console.log(`Selected option: ${option}`);
    // Here you can add logic to handle different options
    // For example, navigate to different pages or trigger different actions

    // Close the modal after selection
    setShowPostVideoModal(false);
  };

  // Calculate progress percentage
  const progress = ((10 - timeRemaining + 1) / 10) * 100;

  // Check if loading or error
  if (loading) {
    return (
      <React.Fragment>
        <StartupHeader />
        <div className="main-content">
          <Container>
            <div className="text-center mt-5">
              {/* <h3>Loading video...</h3> */}
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }

  if (error) {
    return (
      <React.Fragment>
        <StartupHeader />
        <div className="main-content">
          <Container>
            <div className="text-center mt-5">
              <h3>Error: {error}</h3>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }

  // Get the demo video URL from metadata or use the default
  const demoVideoUrl =
    videoMetadata?.video_url ||
    "https://elqmq.upcloudobjects.com/demo-videos/mod/v2-sample-group.mp4";

  return (
    <React.Fragment>
      <StartupHeader />

      <div className="main-content">
        <div className="main-banner" style={{ height: "100vh" }}>
          <div className="dashboard-wrapper resumes-page">
            <Container fluid style={{ maxWidth: "1500px" }}>
              <div className="dashboard-col-bg public-wrapper outline-form">
                <Row className="justify-content-md-center">
                  <Col md={12} className="mx-auto">
                    <h1 className="mb-5 ml-3">JobInfoNetwork</h1>

                    {/* Intro Video */}
                    {activeSection === 0 && (
                      <VideoPlayer
                        videoUrl={
                          "https://elqmq.upcloudobjects.com/demo-videos/mod/v1-sample-group-intro.mp4"
                        }
                        timeStampCategories={processedIntroParts}
                        useLandscape={true}
                        onCategoryClick={(index) => {
                          if (index === 0) {
                            setActiveSection(0);
                          } else {
                            setActiveSection(2);
                          }
                        }}
                        onVideoEnd={() => setActiveSection(1)}
                      />
                    )}

                    {/* Emails */}
                    {activeSection === 1 && (
                      <div>
                        <img
                          alt="Emails"
                          style={{ width: "100%" }}
                          src="https://elqmq.upcloudobjects.com/demo-videos/mod/emails.png"
                        />
                        <div className="mt-3">
                          <ProgressBar now={progress} animated={true} />
                          <Button onClick={togglePause} className="mt-3">
                            {timerPaused ? "Next" : "Pause"}
                          </Button>
                        </div>
                      </div>
                    )}

                    {/* Main Demo Video */}
                    {activeSection === 2 && (
                      <VideoPlayer
                        videoUrl={demoVideoUrl}
                        timeStampCategories={processedDemoParts}
                        useLandscape={false}
                        onCategoryClick={(index) => {
                          if (index === 0) {
                            setActiveSection(0);
                          } else {
                            setActiveSection(2);
                          }
                        }}
                        onVideoEnd={() => setShowPostVideoModal(true)}
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </div>
      </div>

      {/* Post Video Modal */}
      <PostVideoModal
        show={showPostVideoModal}
        onHide={() => setShowPostVideoModal(false)}
        onOptionSelected={handleOptionSelected}
      />
    </React.Fragment>
  );
}
